.colorWrapper {
    width: 100%;
    background: #C8102E;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentBlock {
    width: 100%;
    position: relative;
    margin: 170px 0 0 0;
}

.container {
    padding: 0 100px;
}

.bgImg {
    width: 99vw;
}

.topText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
    width: 230px;
    text-align: left;
    position: absolute;
    left: 100px;
    top: 108px;
}

.bottomText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
    width: 230px;
    text-align: left;
    position: absolute;
    right: 100px;
    bottom: 72px;
}

.btnBlock {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #C8102E;
}

.my_btn {
    width: 190px;
    height: 40px;
    background: #C8102E;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: .5s all ease;
}

.my_btn:hover {
    color: #fff;
    background: #2B3F75;
    transition: .5s all ease;
    border: 1px solid #C8102E;
}


.descriptionBlock {
    padding: 170px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.textBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 1100px;
}

.line {
    height: 2px;
    background: #C8102E;
    width: 100%;
    margin: 30px 0;
}


.listBlock {
    display: flex;
}

.point {
    width: 4px;
    height: 4px;
    margin-inline-end: 10px;
}
.pointBlock {
    display: flex;
    margin-right: 30px;
    width: calc(100% - 60px);
}

.pointBlock:last-child {
    margin-right: 0;
}

.pointBlock:first-child {
    margin-left: 10px;
}

.pointText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
}

.bgMobile {
    display: none;
}

.bgImg {
    display: block;
}

@media screen and (max-width: 1440px) {
    .bgImg {
        width: 100vw;
    }
}


@media screen and (max-width: 768px) {

    .bgImg {
        display: none;
    }

    .bgMobile {
        width: 100vw;
        display: block;
    }

    .contentBlock {
        width: 100%;
        position: relative;
        margin: 64px 0 0 0;
    }

    .container {
        padding: 0 16px;
    }

    .descriptionBlock {
        padding: 64px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .textBlock {
        font-size: 16px;
        width: auto;
    }

    .listBlock {
        flex-direction: column;
    }

    .pointBlock:first-child {
        margin-left: 0;
    }

    .pointBlock {
        margin-bottom: 12px;
    }

    .topText {
        font-size: 12px;
        width: 128px;
        left: 25px;
        top: 34px;
    }

    .bottomText {
        font-size: 12px;
        width: 128px;
        right: 25px;
        bottom: 0px;
    }
}
