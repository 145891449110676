
.wrapper {
    margin: 0 170px;
    padding: 100px 0;
}

.container {
    display: flex;
    justify-content: space-between;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #252828;
    width: 420px;
}

.inputs_block {
    display: flex;
    margin-bottom: 20px;
}

.input_name {
    margin-bottom: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 8px 10px;
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    outline: none;
    box-sizing: border-box;
}

.input_name_block {
    margin-inline-end: 26px;
}

.my_textarea {
    width: 620px;
    height: 117px;
}

.my_btn {
    width: 190px;
    height: 39px;
    outline: none;
    cursor: pointer;
    background: #C8102E;
    border: 1px solid #C8102E;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #F6F7FA;
    justify-content: center;
    margin-top: 25px;
}

.my_btn:hover {
    background-color: #2B3F75;
    border-color: #2B3F75;
    transition: 0.3s all ease;
}


.error {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #C8102E;
    margin-top: 25px;
}
