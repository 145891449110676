.wrapper {
    margin: 170px 0;
    display: flex;
}

.descBlock {
    width: 40%;
    background: #252828;
    padding-top: 95px;
    padding-inline-start: 100px;
}

.cardImg {
    width: 60%;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 24px;
    width: 70%;
}

.text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #AEBABA;
    margin-bottom: 40px;
    width: 70%;
}

@media screen and (max-width: 768px) {
    .wrapper {
        padding-top: 64px;
        flex-direction: column-reverse;
        background-color: #252828;
        margin: 0 0 96px 0;
    }

    .descBlock {
        width: 100%;
        padding: 36px 16px;
    }

    .cardImg {
        width: 100%;
    }

    .title {
        font-size: 18px;
        margin-bottom: 24px;
        width: 100%;
        line-height: 140%;
        letter-spacing: 0.1em;
    }

    .text {
        font-size: 12px;
        margin-bottom: 28px;
        width: 100%;
    }

    .buttonWrapperMobile {
        display: flex;
        justify-content: center;
        width: 100%;
    }

}
