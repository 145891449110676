.container {
    padding: 0 100px;
}

.descriptionTwoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textBlockTwoLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #252828;
    width: 615px;
    padding: 170px 0;
    margin: 0 10px;
}

.lineShort {
    height: 2px;
    background: #C8102E;
    width: 210px;
    margin: 30px 0;
}


.descriptionBlock {
    padding: 170px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.textBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 1100px;
}

.line {
    height: 2px;
    background: #C8102E;
    width: 100%;
    margin: 30px 0;
}

.listBlock {
    display: flex;
    width: 100%;
}

.point {
    width: 4px;
    height: 4px;
    margin-inline-end: 10px;
}
.pointBlock {
    display: flex;
    margin-right: 10px;
    width: calc(100% - 20px);
}

.pointBlock:last-child {
    margin-right: 0;
}

.pointText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
}

.bottomWrapper {
    position: relative;
    width: 100%;
    height: fit-content;
}

.bottomBg {
    position: relative;
    width: 100%;
}

.btnWrapper {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(calc(50% - 110px) - 33%);
}
