
.container {
    padding: 0 100px;
}

.descriptionTwoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textBlockTwoLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #252828;
    width: 615px;
    padding: 170px 0;
    margin: 0 10px;
}

.lineShort {
    height: 2px;
    background: #C8102E;
    width: 210px;
    margin: 30px 0;
}

.colorWrapper {
    width: 100%;
    background: #252828;
    display: flex;
    justify-content: center;
    align-items: center;
    height:fit-content;
}

.contentBlock {
    width: 100%;
    position: relative;
    margin: 40px 0;
}

.container {
    padding: 0 100px;
}

.bgImg {
    width: 99vw;
}

.topText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
    width: 230px;
    text-align: left;
    position: absolute;
    left: 100px;
    top: 108px;
}

.bottomText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
    width: 230px;
    text-align: left;
    position: absolute;
    right: 100px;
    bottom: 72px;
}

.fake {
    height: 400px;
}


.welcome {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.contacts {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: flex-start;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.cyprus_title {
    margin-bottom: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
}

.img {
    margin-inline-end: 10px;
}

.descriptionBlock {
    padding: 170px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.textBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 1100px;
}

.line {
    height: 2px;
    background: #C8102E;
    width: 100%;
    margin: 30px 0;
}


.listBlock {
    display: flex;
    width: 100%;
}

.point {
    width: 4px;
    height: 4px;
    margin-right: 10px;
}

.pointBlock {
    display: flex;
    margin-right: 10px;
    width: calc(100% - 20px);
}

.pointBlock:last-child {
    margin-right: 0;
}

.pointText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
}


.social_link {
    display: flex;
    align-items: center;
    margin-inline-end: 20px;
    cursor: pointer;
}

.social_link_text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: #252828;
}

