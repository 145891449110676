.wrapper {
    margin-inline-start: 100px;
    margin-block-end: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #252828;
    margin-bottom: 40px;
    width: 310px;
}

.listWrapper {
    position: relative;
}

.list {
    position: absolute;
    height: 100%;
    top: 170px;
    left: 105px;
    width: 715px;
}

.listItem {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #252828;
    margin-bottom: 15px;
}

.text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #FFFFFF;
    margin-inline-start: 10px;
}

.listItem:last-child {
    border-bottom: none;
}

.btnWrapperMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .wrapper {
        margin: 0 0 96px 0;
        flex-direction: column;
        align-items: center;
    }

    .list {
        position: absolute;
        height: 100%;
        top: 28px;
        left: 16px;
        width: auto;
        padding-right: 16px;
    }

    .bg {
        width: 100vw;
        height: 800px;
    }

    .btnWrapper {
        display: none;
    }

    .title {
        font-size: 18px;
        margin-top: 64px;
    }

    .btnWrapperMobile {
        display: block;
        margin-top: 28px;
    }
}
