.wrapper {
    margin-block-end: 170px;
    margin-inline-start: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #252828;
    margin-bottom: 40px;
    width: 400px;
}

.listWrapper {
    position: relative;
}

.list {
    position: absolute;
    height: 100%;
    top: 170px;
    left: 105px;
    width: 715px;
}

.text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.myBtn {
    width: 190px;
    height: 40px;
    border: 1px solid #FFFFFF;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    transition: .5s all ease;
}

.myBtn:hover {
    background: #C8102E;
    border: 1px solid #C8102E;
    transition: .5s all ease;
}

.btnBlock {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
