.homeWrapper {
}

.homeContent {
    width: 100%;
    padding: 0 100px;
}

.main {
    overflow: hidden;
    height: 100vh;
}

.main_bg {
    width: 100%;
    height: 100vh;
}

.descriptionBlock {
    padding: 170px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.textBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 1100px;
}

.line {
    height: 2px;
    background: #C8102E;
    width: 100%;
    margin: 30px 0;
}

.bankingServicesBlock {
    background: #252828;
    padding: 120px 0;
}

.servicesTitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 878px;
    margin: 0 auto;
}

.iconsWrapper {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}

.iconsWrapperRowTwo {
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
}

.descriptionTwoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textBlockTwoLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 760px;
    margin: 0 30px;
    padding: 170px 0;
}

.lineShort {
    height: 2px;
    background: #C8102E;
    width: 210px;
    margin: 30px 0;
}

.bannerBlock {
    display: flex;
    justify-content: center;
    position: relative;
}

.name {
    position: absolute;
    top: 268px;
    right: 78px;
    width: 300px;
}

.banner {
    width: 100%;
}

.listBlock {
    display: flex;
}

.point {
    width: 4px;
    height: 4px;
    margin-inline-end: 10px;
}

.pointBlock {
    display: flex;
    margin-right: 30px;
    width: calc(100% - 60px);
}

.pointText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
}

.mobileFlex {
    display: none;
}

.nameMobile {
    display: none;
}

@media screen and (max-width: 1440px) {
    .name {
        position: absolute;
        top: 205px;
        right: 78px;
        width: initial;
    }
}

@media screen and (max-width: 768px) {

    .desktop {
        display: none;
    }

    .homeContent {
        width: 100%;
        padding: 0 16px;
    }

    .textBlock {
        font-size: 16px;
        text-align: center;
        width: auto;
    }

    .servicesTitle {
        font-weight: 500;
        font-size: 18px;
        width: auto;
        margin-bottom: 36px;
        text-align: start;
    }

    .iconsWrapper {
        flex-direction: column;
    }

    .iconsWrapperRowTwo {
        flex-direction: column;
    }

    .mobileFlex {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .descriptionTwoLine {
        flex-direction: column;
    }

    .textBlockTwoLine {
        font-weight: 400;
        font-size: 16px;
        width: auto;
        margin: 0 30px;
        padding: 30px 0;
    }

    .descriptionBlock {
        padding: 30px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .listBlock {
        flex-direction: column;
    }

    .pointBlock {
        margin-bottom: 12px;
        margin-right: 0;
        width: auto;
    }

    .name {
        display: none;
    }

    .nameMobile {
        position: absolute;
        top: calc(25% - 14px);
        right: calc(25% - calc(22% - 17px));
        width: initial;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }

    .bankingServicesBlock {
        padding: 64px 0;
    }

    .pointText {
        font-size: 16px;
    }
}
